import React, { useEffect, useRef } from "react"
import { useField } from "@unform/core"

const Input = ({ name, placeholder, ...rest }) => {
  const inputRef = useRef(null)
  const { fieldName, defaultValue = "", registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    })
  }, [fieldName, registerField])

  return (
    <>
      <input
        ref={inputRef}
        id={fieldName}
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...rest}
      />

      {error && <span style={{ color: "#f00" }}>{error}</span>}
    </>
  )
}

export default Input